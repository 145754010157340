import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { microsToTimestamp, binarySearch } from "../../common.js";

export function SeekPreview({ time, progress, posterUrl }) {
  return (
    <>
      <div
        style={{
          position: "absolute",
          left: `${progress * 100}%`,
          top: "-200px",
          transform: "translateX(-50%)",
        }}
      >
        <img src={posterUrl} height="150px" width="auto" alt="Seek preview" />
      </div>
      <div
        style={{
          position: "absolute",
          left: `${progress * 100}%`,
          transform: "translateX(-50%)",
          top: "-30px",
        }}
      >
        {microsToTimestamp(time).replace(/\..*/, "")}
      </div>
    </>
  );
}

const SCRUBBER_SIZE = 16;
const SCRUBBER_BAR_WIDTH = 4;

export default function SeekBar({
  currentTime,
  sceneBoundaries,
  posterUrls,
  onSeekStart,
  seekToScene,
}) {
  const seekBarContainerRef = useRef(null);
  const [dragState, setDragState] = useState(null);

  const maxTime = useMemo(
    () => sceneBoundaries[sceneBoundaries.length - 1].end,
    [sceneBoundaries],
  );
  const currentProgress = useMemo(
    () => currentTime / maxTime,
    [currentTime, maxTime],
  );

  const extractClientX = (e) =>
    e.touches ? (e.touches.length ? e.touches[0].clientX : null) : e.clientX;
  const clientXToProgress = useCallback((clientX) => {
    if (!seekBarContainerRef.current) return 0;
    const rect = seekBarContainerRef.current.getBoundingClientRect();
    return Math.max(0, Math.min(1, (clientX - rect.left) / rect.width));
  }, []);
  const progressToTime = useCallback(
    (progress) => progress * maxTime,
    [maxTime],
  );
  const timeToScene = useCallback(
    (time) =>
      Math.max(0, binarySearch(sceneBoundaries, time, (x) => x.start).low),
    [sceneBoundaries],
  );

  const makeDragState = useCallback(
    (e) => {
      const x = extractClientX(e);
      if (x === null) return null;
      const progress = clientXToProgress(x);
      const time = progressToTime(progress);
      const scene = timeToScene(time);
      return { x, progress, time, scene };
    },
    [clientXToProgress, progressToTime, timeToScene],
  );

  const handleStart = useCallback(
    (e) => {
      e.stopPropagation();
      setDragState(makeDragState(e));
      onSeekStart();
    },
    [makeDragState, onSeekStart],
  );

  const handleMove = useCallback(
    (e) => {
      if (!dragState) return;
      setDragState(makeDragState(e));
    },
    [dragState, makeDragState],
  );

  const handleEnd = useCallback(
    (e) => {
      if (!dragState) return;
      e.stopPropagation();
      const finalDragState = makeDragState(e) ?? dragState;
      seekToScene(finalDragState.scene);
      setDragState(null);
    },
    [dragState, makeDragState, seekToScene],
  );

  useEffect(() => {
    if (dragState) {
      document.addEventListener("mousemove", handleMove);
      document.addEventListener("mouseup", handleEnd);
      document.addEventListener("touchmove", handleMove);
      document.addEventListener("touchend", handleEnd);
    }
    return () => {
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("mouseup", handleEnd);
      document.removeEventListener("touchmove", handleMove);
      document.removeEventListener("touchend", handleEnd);
    };
  }, [dragState, handleMove, handleEnd]);

  const noPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      style={{
        zIndex: 20,
        position: "absolute",
        height: "100%",
        width: "100%",
        /* Prevent user from selecting the SeekPreview (this gets triggered
         * annoyingly when the user drags the seek bar) */
        "-webkit-user-select": "none" /* Safari */,
        "-moz-user-select": "none" /* Firefox */,
        "-ms-user-select": "none" /* IE/Edge */,
        "user-select": "none" /* Standard */,
      }}
      onMouseDown={handleStart}
      onTouchStart={handleStart}
      onDragStart={handleStart}
      onClick={noPropagation}
      className="swiper-no-swiping"
    >
      <div
        ref={seekBarContainerRef}
        style={{
          zIndex: 20,
          position: "absolute",
          bottom: SCRUBBER_SIZE / 2,
          left: 0,
          width: "100%",
          height: `${SCRUBBER_BAR_WIDTH}px`,
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: `${(dragState?.progress ?? currentProgress) * 100}%`,
            height: "100%",
            backgroundColor: "red",
          }}
        />
        {/* Scrubber circle */}
        <div
          style={{
            position: "absolute",
            left: `calc(${(dragState?.progress ?? currentProgress) * 100}% - ${
              SCRUBBER_SIZE / 2
            }px)`,
            top: -(SCRUBBER_SIZE / 2 - SCRUBBER_BAR_WIDTH / 2),
            width: SCRUBBER_SIZE,
            height: SCRUBBER_SIZE,
            borderRadius: "50%",
            backgroundColor: "red",
            cursor: "move",
          }}
        />

        {dragState && (
          <SeekPreview
            progress={dragState.progress}
            time={dragState.time}
            posterUrl={posterUrls?.[dragState.scene]}
          />
        )}
      </div>
    </div>
  );
}
