import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import BreakdownButton from "../atoms/BreakdownButton";

import IconBars from "../../assets/icons/bars.svg";
import IconCaretDown from "../../assets/icons/caret-down.svg";
import IconCaretUp from "../../assets/icons/caret-up.svg";

const DropdownWrapper = styled.span`
  position: relative;
  cursor: pointer;
`;

const ContextMenu = styled.div`
  position: absolute;
  top: ${({ $top }) => $top};
  left: ${({ $direction }) => ($direction === "left" ? "0" : "auto")};
  right: ${({ $direction }) => ($direction === "right" ? "0" : "auto")};
  display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
  z-index: 6;
  border: 1px solid rgba(151, 151, 151, 0.8);
  background-color: rgba(32, 32, 32, 0.95);
  border-radius: 10px;
  text-wrap: nowrap;
  overflow-y: auto;
  max-height: 60dvh;
`;

const ContextMenuItem = styled.div`
  padding: 0.5rem;
  cursor: ${({ $enabled }) => ($enabled ? "pointer" : "not-allowed")};
  opacity: ${({ $enabled }) => ($enabled ? 1 : 0.5)};
  &:hover {
    background-color: ${({ $enabled }) =>
      $enabled ? "rgba(64, 64, 64, 0.95)" : "inherit"};
  }
  text-align: left;

  &:not(:first-child) {
    border-top: 1px solid rgba(151, 151, 151, 0.8);
  }
`;

const DropdownLabel = styled.span`
  display: grid;
  place-items: center;
`;

export default function Dropdown({
  title,
  style = {},
  buttonWidth,
  options = [],
  direction = "left", // supported values: left, center, right
  stretchContextMenu = false,
  buttonFontSize = 24,
  buttonBorderRadius = 10,
  buttonVerticalPadding = 5,
  buttonHorizontalPadding = 10,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleOptionClick = (action, enabled) => {
    if (enabled) {
      setIsOpen(false);
      action();
    }
  };

  const img = (src) => (
    <img src={src} width={buttonFontSize} height={buttonFontSize} />
  );

  return (
    <DropdownWrapper ref={dropdownRef} style={style}>
      <BreakdownButton
        ref={buttonRef}
        $fitContent
        $width={buttonWidth}
        className="breakdown-button"
        onClick={(event) => {
          event.stopPropagation(); // Clicking this button should not count as clicking the breakdown overlay
          setIsOpen(!isOpen);
        }}
        $noCapitalize
        $fontSize={buttonFontSize}
        $borderRadius={buttonBorderRadius + "px"}
        $padding={`${buttonVerticalPadding}px ${buttonHorizontalPadding}px`}
      >
        {!isOpen && (
          <DropdownLabel>
            {title && <>{title}&nbsp;</>}
            {img(title ? IconCaretDown : IconBars)}
          </DropdownLabel>
        )}
        {isOpen && (
          <DropdownLabel>
            {title && <>{title}&nbsp;</>}
            {img(IconCaretUp)}
          </DropdownLabel>
        )}
        <ContextMenu
          ref={dropdownRef}
          $isOpen={isOpen}
          $direction={direction}
          $top={
            buttonRef.current ? `${buttonRef.current.offsetHeight}px` : "100%"
          }
          style={{
            width:
              buttonRef.current && stretchContextMenu
                ? buttonRef.current.offsetWidth
                : "auto",
          }}
        >
          {options
            .filter((e) => e)
            .map(({ label, action, enabled = true }, i) => (
              <ContextMenuItem
                key={i}
                onClick={() => handleOptionClick(action, enabled)}
                style={stretchContextMenu ? { textWrap: "wrap" } : {}}
                $enabled={enabled}
              >
                {label}
              </ContextMenuItem>
            ))}
        </ContextMenu>
      </BreakdownButton>
    </DropdownWrapper>
  );
}
