import styled, { css } from "styled-components";

const CenterChild = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $full }) =>
    $full &&
    css`
      width: 100dvw;
      height: 100dvh;
    `}

  ${({ backgroundImage }) => css`
    background: url(${backgroundImage}) no-repeat center;
    background-size: 100%;
  `}
`;

export default CenterChild;
