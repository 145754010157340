import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getRequest } from "../common.js";
import Loading from "./Loading.js";
import Page from "./layout/Page.js";
import Header from "./layout/Header.js";
import BackButton from "./atoms/BackButton.js";
import { useNavigate } from "react-router-dom";
import ChatPopup from "./breakdown/ChatPopup.js";
import { fetchSceneUrl } from "./AnkiCardView.js";
import { colors } from "./constants.js";

const PageContainer = styled.div`
  height: 100dvh; // Set the height to fill the viewport
  overflow-y: auto; // Enable vertical scrolling
  display: flex;
  flex-direction: column;
`;

// Styled components
const ThreadBox = styled.div`
  background-color: #1e1e1e;
  // border: 1px solid white;
  border-radius: 10px;
  margin-bottom: 20px;
`;

const BoxHeader = styled.div`
  display: flex;
  background-color: #3e3e3e;
  border-radius: 10px;
  padding: 10px;
  color: #999999;
`;

const WordFormatting = styled.span`
  font-weight: bold;
  color: #ffffff;
`;

const ThreadItem = styled.div`
  padding: 10px;
  border-bottom: 1px solid white;
  &:last-child {
    border-bottom: none;
  }
  text-align: center;
`;

const SmallRightButton = styled.button`
  margin-left: auto;

  border: 1px solid rgba(151, 151, 151, 0.8);
  outline: none;
  background: none;
  color: white;
  border-radius: 10px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.25rem 0.25rem;
  transition: 0.2s border;

  &:focus,
  &:hover {
    outline: none;
    border-color: ${colors.primary};
  }
`;

export default function ChatOverview() {
  const [threads, setThreads] = useState(null);
  const [selectedThread, setSelectedThread] = useState(null);
  const navigate = useNavigate();
  const [popupState, setPopupState] = useState(null);

  useEffect(() => {
    document.title = "Chats - LingoFlix";
  }, []);

  useEffect(() => {
    getRequest("/api/chat/threads", {}, setThreads);
  }, []);

  // Group threads by sentence and word
  const threadUi = (threads) => {
    const groupedThreads = threads.reduce((acc, thread) => {
      if (!acc[thread.username]) {
        acc[thread.username] = {};
      }
      const key = `${thread.sentence}-${thread.word || ""}`;
      if (!acc[thread.username][key]) {
        acc[thread.username][key] = [];
      }
      acc[thread.username][key].push(thread);
      return acc;
    }, {});
    const myThreads = threads.filter((t) => t.isMine);
    const myUsername = myThreads.length ? myThreads[0].username : null;
    const userGroups = (
      myUsername
        ? Array.from(new Set([myUsername, ...Object.keys(groupedThreads)]))
        : Object.keys(groupedThreads)
    ).map((username) => groupedThreads[username]);

    const openScene = async (thread) => {
      const url = await fetchSceneUrl(thread.episode, thread.timeMicros);
      navigate(url);
    };

    return userGroups.map((thisGroup, i) => (
      <React.Fragment key={i}>
        <h2 style={{ marginBottom: "0.5rem", textAlign: "center" }}>
          {Object.values(thisGroup)[0][0].isMine
            ? "Your"
            : Object.values(thisGroup)[0][0].username + "'s"}{" "}
          Chats
        </h2>
        {Object.values(thisGroup).map((threads, j) => (
          <ThreadBox key={j}>
            <BoxHeader>
              <span>
                {threads[0].word ? (
                  threads[0].sentence
                    .split(threads[0].word)
                    .map((part, index, array) => (
                      <React.Fragment key={index}>
                        {part}
                        {index < array.length - 1 && (
                          <WordFormatting>{threads[0].word}</WordFormatting>
                        )}
                      </React.Fragment>
                    ))
                ) : (
                  <WordFormatting>{threads[0].sentence}</WordFormatting>
                )}
              </span>
              <SmallRightButton onClick={() => openScene(threads[0])}>
                Open Scene
              </SmallRightButton>
            </BoxHeader>
            {threads.map((thread, index) => (
              <ThreadItem
                key={index}
                onClick={() => {
                  setSelectedThread(thread);
                  setPopupState({ popup: "chat" });
                }}
              >
                {thread.name}
              </ThreadItem>
            ))}
          </ThreadBox>
        ))}
      </React.Fragment>
    ));
  };

  if (!threads) {
    return <Loading />;
  }

  const headerLeftContent = <BackButton onClick={() => navigate("/")} />;
  const word =
    selectedThread && selectedThread.word
      ? {
          index: selectedThread.wordIndex,
          original: selectedThread.word,
        }
      : undefined;
  return (
    <PageContainer>
      <Page>
        <Header $left={headerLeftContent} $center={<h1>Latest Chats</h1>} />
        {threadUi(threads)}
        {selectedThread && popupState && (
          <ChatPopup
            episode={{ id: selectedThread.episode }}
            videoPath={""}
            parentBreakdown={{
              index: selectedThread.breakdownIndex,
              original: selectedThread.sentence,
              words: word ? [word] : [],
            }}
            childBreakdown={word}
            defaultThread={selectedThread}
            scrollStart={true}
            popupState={popupState}
            setPopupState={setPopupState}
          />
        )}
      </Page>
    </PageContainer>
  );
}
