import React, { useEffect, useState } from "react";
import Page from "../layout/Page.js";
import Collection from "../layout/Collection.js";
import CollectionRow from "../layout/CollectionRow.js";
import ShowsList from "./ShowsList.js";
import ProfileLabel from "../ProfileLabel.js";
import ContinueWatching from "./ContinueWatching.js";
import Column from "../layout/Column.js";
import styled from "styled-components";
import Header from "../layout/Header.js";
import Dropdown from "../breakdown/Dropdown.js";
import { getRequest, logEvent, postRequest } from "../../common.js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import IconDeck from "../../assets/icons/cards-deck.svg";
import IconChat from "../../assets/icons/chat-white.svg";
import IconSliders from "../../assets/icons/sliders.svg";
import IconBan from "../../assets/icons/ban.svg";
import IconRightFromBracket from "../../assets/icons/right-from-bracket.svg";
import IconUserPlus from "../../assets/icons/user-plus.svg";

const MainContent = styled.main`
  margin-top: 2rem;
  margin-bottom: 4rem;
`;

export default function Home() {
  const [nrOfCards, setNrOfCards] = useState();
  const [nrOfChats, setNrOfChats] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getRequest("/api/anki/user_cards_nr")
      .then((result) => setNrOfCards(result.nrOfCards))
      .catch((e) => console.error("ERROR updateNrOfCards", e));
  }, []);

  useEffect(() => {
    getRequest("/api/chat/nr_threads")
      .then((result) => setNrOfChats(result.count))
      .catch((e) => console.error("ERROR updateNrOfChats", e));
  }, []);

  useEffect(() => {
    document.title = "LingoFlix";
  }, []);

  async function handlePressInviteFriend() {
    navigate("/invite-menu");
    logEvent("press-invite-friend");
  }

  async function handlePressSettings() {
    navigate("/settings");
    logEvent("press-settings");
  }

  async function handlePressDeleteAccount() {
    logEvent("press-delete-account");
    try {
      await postRequest(
        "/api/login/send-delete-link",
        {},
        () => {
          toast(
            <div>
              <img src={IconBan} width="16" height="16" alt="Check" />
              &nbsp; Check your email for a link to delete your account (valid
              for ten minutes)
            </div>,
          );
        },
        true,
      );
    } catch (e) {
      console.error("send delete link fetch error", e.message);
    }
  }

  async function handlePressLogOut() {
    logEvent("press-log-out");
    try {
      await getRequest(
        "/api/login/logout",
        {},
        () => {
          window.location.href = "/";
        },
        true,
      );
    } catch (e) {
      console.error("logout fetch error", e.message);
    }
  }

  function centeredOption(icon, label, action) {
    return {
      label: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-st`",
            gap: "1rem",
          }}
        >
          <img src={icon} width="40" height="40" alt={label} />
          {label}
        </div>
      ),
      action,
    };
  }

  return (
    <Page>
      <Column>
        <Header
          $left={<ProfileLabel />}
          $center={<h1>Home</h1>}
          $right={
            <Dropdown
              direction="right"
              buttonWidth="200px"
              options={[
                centeredOption(IconDeck, `Anki Deck (${nrOfCards})`, () =>
                  navigate("/anki-card-view"),
                ),
                centeredOption(IconChat, `Chats (${nrOfChats})`, () =>
                  navigate("/chat"),
                ),
                centeredOption(IconSliders, "Settings", handlePressSettings),
                centeredOption(
                  IconUserPlus,
                  "Invite Friend",
                  handlePressInviteFriend,
                ),
                centeredOption(
                  IconBan,
                  "Delete Account",
                  handlePressDeleteAccount,
                ),
                centeredOption(
                  IconRightFromBracket,
                  "Log Out",
                  handlePressLogOut,
                ),
              ]}
            />
          }
        />
        <MainContent>
          <ContinueWatching />
          <Collection>
            <h2>All Shows</h2>
            <CollectionRow>
              <ShowsList />
            </CollectionRow>
          </Collection>
        </MainContent>
      </Column>
    </Page>
  );
}
