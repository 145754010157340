import styled from "styled-components";

export default styled.div`
  display: flex;
  box-sizing: border-box;
  position: relative;
  left: -30px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  padding-left: 10px;
  width: 100dvw;
  max-width: calc(100% + 60px);
  overflow: auto;
  flex-wrap: ${({ $wrap }) => ($wrap ? "wrap" : "nowrap")};

  & > * {
    margin-bottom: ${({ $wrap }) => ($wrap ? "20px" : "0")};
  }

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px grey;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #444444;
      border-radius: 5px;
    }
  }
`;
