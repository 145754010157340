import React, { useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import DetailedSubs from "./DetailedSubs.js";
import { isMobileOnly } from "react-device-detect";

const BottomBar = styled.div`
  overflow-x: auto;
  padding: 0.5rem ${isMobileOnly ? 0.25 : 1.0}rem;
  margin-top: 0.5rem;
  overflow: visible; // We need this for the dropdown menu if there's not much content.
`;

const slideIn = ($start, $end) => keyframes`
  0% {
    left: ${$start};
  }
  100% {
    left: ${$end};
  }
`;

const BottomSlideLayout = styled.div`
  /* Prevent user from selecting text (this gets triggered annoyingly when the user drags the seek bar) */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* Standard */

  max-height: ${({ $preferredHeight, $videoHeight }) =>
    "calc(max(" +
    Math.max(40, Math.min($preferredHeight, 70)) +
    "dvh, " +
    $videoHeight +
    "px))"};
  min-height: ${({ $videoHeight }) =>
    $videoHeight ? "calc(100% - " + $videoHeight + "px)" : "auto"};
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: ${({ $bottom, $isVisible }) => ($isVisible ? $bottom : "-100%")};
  bottom: calc(
    ${({ $bottom, $isVisible }) => ($isVisible ? $bottom : "-100%")} - 1px
  );
  width: 100%;
  ${({ $animation }) =>
    $animation &&
    css`
      animation: ${slideIn($animation.start, $animation.end)} 0.3s ease-in-out
        forwards;
    `}
  transition: bottom 0.3s ease-in-out;
  background: rgba(32, 32, 32, 0.8);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  overflow-y: ${({ $scrollable }) => ($scrollable ? "auto" : "hidden")};

  &::before {
    /* We duplicate max-height and min-height to make sure the blur effect covers the entire region even if a scroll happens */
    max-height: ${({ $preferredHeight, $videoHeight }) =>
      "calc(max(" +
      Math.max(40, Math.min($preferredHeight, 70)) +
      "dvh, " +
      $videoHeight +
      "px))"};
    min-height: ${({ $videoHeight }) =>
      $videoHeight ? "calc(100% - " + $videoHeight + "px)" : "auto"};
    content: "";
    position: absolute;
    inset: 0; /* Shorthand for top/right/bottom/left: 0 */
    background: rgba(32, 32, 32, 0.8);
    filter: blur(7px);
    z-index: -1;
  }
`;

export default function BreakdownOverlay({
  overlayRef,
  episode,
  breakdown,
  breakdownMenuOptions,
  isVisible,
  animation,
  preferredHeight,
  videoHeight,
  getWordBreakdownKnowledgeState,
  setWordBreakdownKnowledgeState,
  setBreakdownScroll,
  popoverState,
  setPopoverState,
  popupState,
  setPopupState,
  registerPopoverButton,
  popoverHasScrollbars,
  isSeekBarVisible,
  setIsSeekBarVisible,
  onClick,
}) {
  useEffect(() => {
    const handleScroll = () => {
      if (overlayRef.current) {
        setBreakdownScroll(overlayRef.current.scrollTop);
      }
    };

    const element = overlayRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [!popoverState, setBreakdownScroll]);

  useEffect(() => {
    if (overlayRef.current) {
      overlayRef.current.scrollTo(0, 0);
    }
  }, [breakdown]);

  return (
    <BottomSlideLayout
      $bottom={"0px"}
      $isVisible={isVisible}
      onAnimationEnd={animation.onEnd}
      $animation={animation}
      $preferredHeight={preferredHeight}
      $videoHeight={videoHeight}
      ref={overlayRef}
      $scrollable={!popoverHasScrollbars}
      onClick={onClick}
    >
      <BottomBar>
        <DetailedSubs
          episode={episode}
          breakdown={breakdown}
          breakdownMenuOptions={breakdownMenuOptions}
          popoverState={popoverState}
          setPopoverState={setPopoverState}
          getWordBreakdownKnowledgeState={getWordBreakdownKnowledgeState}
          setWordBreakdownKnowledgeState={setWordBreakdownKnowledgeState}
          popupState={popupState}
          setPopupState={setPopupState}
          registerPopoverButton={registerPopoverButton}
          isSeekBarVisible={isSeekBarVisible}
          setIsSeekBarVisible={setIsSeekBarVisible}
        />
      </BottomBar>
    </BottomSlideLayout>
  );
}
