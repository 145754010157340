import React, { useRef, useCallback } from "react";
import styled from "styled-components";

const InvisibleButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
`;

export default function PopoverButton({
  children,
  breakdown,
  popoverState,
  setPopoverState,
  registerPopoverButton,
}) {
  const buttonRef = useRef(null);

  const placePopover = useCallback(
    (breakdown) => {
      if (
        (breakdown || (popoverState && popoverState.breakdown === breakdown)) &&
        buttonRef.current
      ) {
        registerPopoverButton(breakdown.index, buttonRef.current);
        setPopoverState({
          breakdown: breakdown,
        });
      }
    },
    [popoverState, setPopoverState],
  );

  const popoverButtonClick = useCallback(
    (event) => {
      event.stopPropagation(); // Clicking the button should not count as clicking the breakdown overlay
      if (popoverState && popoverState.breakdown === breakdown) {
        setPopoverState(null);
      } else {
        placePopover(breakdown);
      }
    },
    [popoverState, breakdown, setPopoverState, placePopover],
  );

  return (
    <InvisibleButton onClick={popoverButtonClick} ref={buttonRef}>
      {children}
    </InvisibleButton>
  );
}
