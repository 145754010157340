import styled from "styled-components";

export const VIDEO_ASPECT_RATIO = { width: 16, height: 9 };

export default styled.div`
  /* Take up as much room as possible without distorting the aspect ratio */
  width: calc(
    min(
      100dvw,
      ${(VIDEO_ASPECT_RATIO.width / VIDEO_ASPECT_RATIO.height) * 100}dvh
    )
  );
  height: calc(
    min(
      100dvh,
      ${(VIDEO_ASPECT_RATIO.height / VIDEO_ASPECT_RATIO.width) * 100}dvw
    )
  );
  position: absolute;
  z-index: -1;

  /* If the window is wider than the video we want to center it horizontally */
  left: 50%;
  transform: translateX(-50%);
`;
